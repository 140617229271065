import { FilterValue } from '@src/component/page/listing/ListingGlobalFilter';
import { isNull, isUndefined } from '@src/utils/formatChecker';
import { clone } from 'lodash';
import { OnGlobalFilterChange } from '@src/component/page/listing/AllListingTable';
import {
  ListingForBackyardList,
  StateOfListing,
} from '@src/swagger/funwoo.api';

export const backyardListingGlobalFilterHandler: OnGlobalFilterChange<
  ListingForBackyardList
> = (rows, columnIds, filterValue: FilterValue) => {
  if (
    !filterValue.keyword &&
    isUndefined(filterValue.status) &&
    isUndefined(filterValue.soldOut)
  )
    return rows;
  console.log(filterValue);
  let result = clone(rows);
  if (filterValue.keyword) {
    result = result.filter(({ original }) => {
      const acceptable = [
        original.country,
        original.id,
        original.sid,
        original.title,
        original.building_project,
        original.city,
        original.address,
      ];

      return acceptable.some((item) =>
        `${item}`
          .toLocaleLowerCase()
          .includes((filterValue.keyword ?? '').toLocaleLowerCase())
      );
    });
  }

  if (!isUndefined(filterValue.status)) {
    if (isNull(filterValue.status)) return [];
    else {
      result = result.filter(
        ({ original }) => original.status === filterValue.status
      );
    }
  }

  if (!isUndefined(filterValue.soldOut)) {
    if (isNull(filterValue.soldOut)) return [];
    else {
      const onlySold = filterValue.soldOut;
      result = result.filter(({ original }) => {
        const isSold = original.listing_state.some(
          ({ state }) => state === StateOfListing.SOLD
        );
        console.log(onlySold, isSold, original.listing_state);
        return onlySold ? isSold : !isSold;
      });
    }
  }

  return result;
};
