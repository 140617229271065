export const isSet = <Whatever>(
  whatever: Whatever | null | undefined
): whatever is Whatever =>
  whatever !== null &&
  whatever !== undefined &&
  typeof whatever !== 'undefined' &&
  whatever !== void 0;

export const isNotSet = <Whatever>(
  whatever: Whatever | null | undefined
): whatever is null | undefined =>
  whatever === null ||
  whatever === undefined ||
  typeof whatever === 'undefined' ||
  whatever === void 0;

export const isString = <Whatever>(
  whatever: Whatever | string
): whatever is string => typeof whatever === 'string';

export const isEmptyString = (
  string: undefined | string = ''
): string is undefined => string.length === 0;

export const isNotEmptyString = (string: string = '') => string.length !== 0;

export const isEmptyArray = (array: Array<any>) => array.length === 0;

export const isNotEmptyArray = (array: Array<any>) => array.length !== 0;

export const isEmptyObject = (object: Record<any, any>) =>
  Object.keys(object).length === 0;

export const isBoolean = (whatever: any): whatever is boolean =>
  typeof whatever === 'boolean';

export const isTrue = (whatever: any): whatever is true =>
  isBoolean(whatever) && whatever === true;

export const isFalse = (whatever: any): whatever is false =>
  isBoolean(whatever) && whatever === false;

export const isNotTrue = <ExceptTrue>(
  whatever: ExceptTrue | true
): whatever is ExceptTrue => whatever !== true;

export const isObject = <Object extends Record<string, any>>(
  whatever: Object | any
): whatever is Object => {
  return (
    typeof whatever === 'object' && isSet(whatever) && !Array.isArray(whatever)
  );
};

export const isEmptyObj = <Object extends Record<string, any>>(
  whatever: Object | any
): whatever is Object => {
  return isObject(whatever) && JSON.stringify(whatever) === JSON.stringify({});
};

export function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

export function isUndefined<V extends undefined>(
  value: V | any
): value is undefined {
  return value === undefined || value === void 0;
}

export function isNull<V extends null>(value: V | any): value is null {
  return value === null && typeof value === 'object';
}
